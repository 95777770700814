// src/pages/Modal/ModalOverlay.tsx
import { useNavigate } from 'react-router-dom'

import useDimension from '../../hooks/use-dimension'

type Props = {
  color?: string
}

function Overlay({ color = 'rgba(0, 0, 0, 0.15)' }: Props) {
  const { dw } = useDimension()

  const navigate = useNavigate()

  const back = (e: any) => {
    e.stopPropagation()
    navigate(-1)
  }

  return (
    <div
      onClick={back}
      style={{
        position: 'absolute',
        // top: -1.5 * dh,
        // bottom: -1.5 * dh,
        top: -0.1 * window.innerHeight,
        bottom: -0.1 * window.innerHeight,
        left: -1 * dw,
        right: -1 * dw,
        background: color,
        backdropFilter: 'blur(2px)',
      }}
    ></div>
  )
}

export default Overlay
