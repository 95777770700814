// src/context/GameContext.tsx
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { Updater, useImmer } from 'use-immer'

type GameHints = Record<
  string,
  Record<string, Record<string, boolean>>
>

type GamePasses = Record<string, boolean>

type GameAnswers = Record<string, boolean>

type GameState = {
  confirms: Record<string, boolean>
  answers: GameAnswers
  updateAnswers: Updater<GameAnswers>
  passes: GamePasses
  updatePasses: Updater<GamePasses>
  prevPathname: string
  hints: GameHints
  updateHints: Updater<GameHints>
}

const initialState: GameState = {
  confirms: {},
  answers: {},
  updateAnswers: () => {},
  passes: {},
  updatePasses: () => {},
  prevPathname: '/',
  hints: {},
  updateHints: () => {},
}

const GameContext = createContext(initialState)

function GameContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const confirms = useRef<Record<string, boolean>>({})

  const [answers, updateAnswers] = useImmer<GameAnswers>({})
  const [passes, updatePasses] = useImmer<GamePasses>({})

  const prevPathname = useRef<string>('/')
  const location = useLocation()
  useEffect(() => {
    // console.log(location.pathname)
    setTimeout(() => {
      prevPathname.current = location.pathname
    }, 200)
  }, [location])

  const [hints, updateHints] = useImmer<GameHints>({})

  const [value, setValue, remove] = useLocalStorage<{
    answers: GameAnswers
    passes: GamePasses
    hints: GameHints
  } | null>('puzzle-game', null)
  const [init, setInit] = useState(true)
  useEffect(() => {
    if (value !== null) {
      // restore setting
      // console.log({ value })
      updateAnswers(value?.answers ?? {})
      updatePasses(value?.passes ?? {})
      updateHints(value?.hints ?? {})
    } else {
      console.log('no initial game state')
    }
    setTimeout(() => setInit(false), 300)
  }, [setInit, updateAnswers, updatePasses, updateHints])

  useEffect(() => {
    if (init) return
    setValue({ answers, passes, hints })
  }, [init, answers, passes, hints, setValue])

  return (
    <GameContext.Provider
      value={{
        confirms: confirms.current,
        answers,
        updateAnswers,
        passes,
        updatePasses,
        prevPathname: prevPathname.current,
        hints,
        updateHints,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export default GameContextProvider

export const useGameContext = () => useContext(GameContext)
