import { useEffect, useState } from 'react'
import {
  TouchableOpacity,
  TouchableHighlight,
  Text,
  Image,
} from 'react-native'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import styled from 'styled-components'
import PageWrapper from '../../components/PageWrapper'
import useDimension from '../../hooks/use-dimension'
import ModalOverlay from './ModalOverlay'

const puzzleLogo = '/images/check/puzzle-logo.png'

const TextContainer = styled.div<{ height: number }>`
  width: 100%;
  height: ${props => props.height}px;
  background: #fff;
  color: rgb(71, 71, 71);
  overflow: scroll;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-family: 'Noto Sans TC';
`
const CheckContainer = styled.div<{ height: number }>`
  width: 100%;
  height: ${props => props.height}px;
  color: #fff;
  background: rgb(71, 71, 71);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const CheckIcon = styled.div<{
  agree: boolean
  size: number
}>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    width: 65%;
    height: 65%;
    background-color: ${props =>
      props.agree ? 'rgb(42,123,143)' : '#fff'};
    position: absolute;
    transition: background-color 0.2s ease;
  }
`

function PrivacyModal() {
  const { ws, wss, dh, dww } = useDimension()

  const navigate = useNavigate()

  const [started, setStarted] = useLocalStorage('started', '')

  useEffect(() => {
    if (started === 'yes') {
      navigate('/main')
    }
  }, [started, navigate])

  const [agree, setAgree] = useState(false)
  const onAgree = () => {
    if (agree) {
      setAgree(false)
    } else {
      setAgree(true)
      setMsg('')
    }
  }

  const [msg, setMsg] = useState('')
  const onConfirm = () => {
    if (agree) {
      setStarted('yes')
      navigate('/main')
    } else {
      setMsg('您尚未同意拼圖謎的隱私權政策與使用者條款!')
    }
  }

  return (
    <PageWrapper>
      <ModalOverlay color="#fff1" />
      <div
        style={{
          position: 'absolute',
          top: 90 * ws - 50 * ws + dh,
          left: 18.5 * ws + dww,
          width: 323 * ws,
          height: 474 * ws,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <TextContainer height={372 * ws}>
          <div style={{ display: 'flex' }}>
            {/* <img
              src={puzzleLogo}
              style={{ width: 108.5, height: 111.5 }}
              alt="puzzle-logo"
            /> */}
            <Image
              source={{ uri: puzzleLogo }}
              style={{ width: 100 * wss, height: 100 * wss }}
              resizeMode="contain"
              accessibilityLabel="puzzle-logo"
            />
            <h3>使用者條款與隱私權政策</h3>
          </div>
          <div>
            以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。以下為使用者條款與隱私權政策。
          </div>
        </TextContainer>
        <CheckContainer height={51 * ws}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onAgree}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '80%',
              marginTop: -3 * ws,
            }}
          >
            <CheckIcon agree={agree} size={18 * ws} />
            <span
              style={{
                marginLeft: 10 * ws,
                fontSize: 12.5 * ws,
                letterSpacing: 1 * ws,
                fontFamily: 'Noto Sans TC',
                fontWeight: 300,
              }}
            >
              我同意拼圖謎的使用者條款與隱私權政策
            </span>
          </TouchableOpacity>
          {msg !== '' && (
            <span
              style={{
                position: 'absolute',
                bottom: 3 * ws,
                left: 60 * ws,
                marginTop: 34 * ws,
                fontSize: 10.5 * ws,
                letterSpacing: 0.46 * ws,
                color: 'rgb(255,156,156)',
                fontFamily: 'Noto Sans TC',
                fontWeight: 300,
              }}
            >
              您尚未同意拼圖謎的隱私權政策與使用者條款
            </span>
          )}
        </CheckContainer>
        <TouchableHighlight
          onPress={onConfirm}
          style={{
            width: '100%',
            height: 51 * ws,
            backgroundColor: 'rgb(132,149,153)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          underlayColor={'rgb(42,123,143)'}
        >
          <Text
            style={{
              color: '#fff',
              fontSize: 19 * ws,
              letterSpacing: 2.65 * ws,
              fontFamily: 'Noto Sans TC',
            }}
          >
            確認
          </Text>
        </TouchableHighlight>
      </div>
    </PageWrapper>
  )
}

export default PrivacyModal
