// src/components/BtnCopyUrl.tsx
import { WEB_URL } from '../config'

import { useCopyToClipboard } from 'react-use'

const btnCopyURL = '/images/check/btn-copy-url.png'

function BtnCopyUrl() {
  const [state, copyToClipboard] = useCopyToClipboard()

  const onCopy = () => copyToClipboard(WEB_URL)

  return (
    <>
      <div style={{ height: 23 }} />
      <button
        type="button"
        style={{
          outline: 'none',
          border: 'none',
          backgroundColor: '#000',
        }}
        onClick={onCopy}
      >
        <img
          src={btnCopyURL}
          style={{ width: 285, height: 50.5, objectFit: 'contain' }}
          alt="btn-copy-url"
        />
      </button>
      <div style={{ height: 20, marginTop: 10 }}>
        {state.error ? (
          <span style={{ color: '#f00' }}>
            複製失敗: {state.error.message}
          </span>
        ) : (
          state.value && (
            <span style={{ color: '#fff' }}>複製成功!</span>
          )
        )}
      </div>
    </>
  )
}

export default BtnCopyUrl
