import PageWrapper from '../../components/PageWrapper'
import useDimension from '../../hooks/use-dimension'
import ModalOverlay from './ModalOverlay'

export default function TestModal() {
  const { ws } = useDimension()

  return (
    <PageWrapper>
      <ModalOverlay color="#fff4" />
      <div
        style={{
          position: 'absolute',
          top: 229 * ws,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3>Test Modal</h3>
      </div>
    </PageWrapper>
  )
}
