import React, { Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import WelcomePage from './pages/Welcome/Welcome'
import ModalPage from './pages/Modal/ModalPage'
import Fallback from './components/Fallback'
// import Main from './pages/Main'

const MainPage = React.lazy(() => import('./pages/Main'))
const Stage1 = React.lazy(() => import('./pages/Stage1'))
const Stage1Answer = React.lazy(
  () => import('./pages/Stage1/Stage1Answer'),
)
const Stage1Game = React.lazy(
  () => import('./pages/Stage1/Stage1Game/Stage1Game'),
)
const Stage1HintList = React.lazy(
  () => import('./pages/Stage1/Stage1HintList'),
)
const Stage1HintDetail = React.lazy(
  () => import('./pages/Stage1/Stage1HintDetail'),
)
const Stage1Pass = React.lazy(
  () => import('./pages/Stage1/Stage1Pass/Stage1Pass'),
)

export default function PageRoutes() {
  const location = useLocation()

  let state = location.state as { backgroundLocation?: Location }

  return (
    <Suspense fallback={<Fallback />}>
      <div>
        <Routes location={state?.backgroundLocation || location}>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/stage1" element={<Stage1 />} />
          <Route path="/stage1-answer" element={<Stage1Answer />} />
          <Route path="/stage1-game" element={<Stage1Game />} />
          <Route
            path="/stage1-hint-list"
            element={<Stage1HintList />}
          />
          <Route
            path="/stage1-hint-detail"
            element={<Stage1HintDetail />}
          />
          <Route path="/stage1-pass" element={<Stage1Pass />} />
        </Routes>

        {/* Show the modal when a `backgroundLocation` is set */}
        {state?.backgroundLocation && (
          <Routes>
            <Route path="/modal/:id" element={<ModalPage />} />
          </Routes>
        )}
      </div>
    </Suspense>
  )
}
