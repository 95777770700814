import { useEffect } from 'react'
import { TouchableOpacity, Image, View } from 'react-native'
import { useNavigate } from 'react-router-dom'

import PageWrapper from '../../components/PageWrapper'
import useDimension from '../../hooks/use-dimension'
import { useLocalStorage } from 'react-use'

const puzzleSlogan = '/images/welcome/puzzle-slogan.png'
const goodfansLogo = '/images/welcome/goodfans-logo.png'
const welcomeLogo = '/images/welcome/welcome-logo.png'
const btnStartGame = '/images/welcome/btn-start-game.png'

const TOP_OFFSET = 45

function Welcome() {
  const { ws, wss } = useDimension()

  const [started] = useLocalStorage('started', '')

  const navigate = useNavigate()

  useEffect(() => {
    if (started === 'yes') {
      navigate('/main')
    }
  }, [started, navigate])

  const openModal = () =>
    navigate('/modal/privacy', {
      state: {
        backgroundLocation: '/',
      },
    })

  useEffect(() => {
    /* disable overscroll/bounce in safari/chrome */
    document.documentElement.style.position = 'fixed'
    document.documentElement.style.overflow = 'hidden'
    document.documentElement.style.setProperty('--bg-color', '#000')
  }, [])

  return (
    <PageWrapper>
      <View
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            position: 'absolute',
            top: 93 * ws - TOP_OFFSET * ws,
            left: 18.5 * ws,
          }}
        >
          <Image
            source={{ uri: goodfansLogo }}
            style={{ width: 165 * wss, height: 64 * wss }}
            resizeMode="contain"
            accessibilityLabel="goodfans-logo"
          />
        </View>
        <View
          style={{
            position: 'absolute',
            top: 118 * ws - TOP_OFFSET * ws,
          }}
        >
          <Image
            source={{ uri: welcomeLogo }}
            style={{ width: 693 * wss, height: 870 * wss }}
            resizeMode="contain"
            accessibilityLabel="welcome-logo"
          />
        </View>
        <View
          style={{
            position: 'absolute',
            top: 453.5 * ws - TOP_OFFSET * ws,
          }}
        >
          <Image
            source={{ uri: puzzleSlogan }}
            style={{ width: 517 * wss, height: 46 * wss }}
            resizeMode="contain"
            accessibilityLabel="puzzle-slogan"
          />
        </View>
        <TouchableOpacity
          onPress={openModal}
          style={{
            position: 'absolute',
            top: 500 * ws - TOP_OFFSET * ws,
            left: 171 * ws,
          }}
        >
          <Image
            source={{ uri: btnStartGame }}
            style={{ width: 325 * wss, height: 101 * wss }}
            resizeMode="contain"
            accessibilityLabel="btn-start-game"
          />
        </TouchableOpacity>
      </View>
    </PageWrapper>
  )
}

export default Welcome
