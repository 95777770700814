// src/components/PageWrapper.tsx
import React from 'react'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
}

const PageWrapper = ({ children, style = {} }: Props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default PageWrapper
