// src/pages/Modal/ModalPage.tsx
import { Navigate, useParams } from 'react-router-dom'

// import PaperModal from './PaperModal'
// import NicknameModal from './NicknameModal'
// import PrivacyModal from './PrivacyModal'
// import ResetModal from './ResetModal'
// import WrongModal from './WrongModal'
// import WrongSelectModal from './WrongSelectModal'
import TestModal from './TestModal'
import PrivacyModal from './PrivacyModal'

function ModalPage() {
  const { id } = useParams<any>()
  console.log('modal id, ', id)

  if (id === 'test') {
    return <TestModal />
  }
  if (id === 'privacy') {
    return <PrivacyModal />
  }
  // if (id === 'nickname') {
  //   return <NicknameModal />
  // }
  // if (id === 'privacy') {
  //   return <PrivacyModal />
  // }
  // if (id === 'reset') {
  //   return <ResetModal />
  // }

  return <Navigate to="/" replace />
}

export default ModalPage
