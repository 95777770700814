import { BrowserRouter } from 'react-router-dom'

import AppWrapper from './components/AppWrapper'
import GameContextProvider from './context/GameContext'
import PageRoutes from './PageRoutes'

function App() {
  return (
    <BrowserRouter>
      <GameContextProvider>
        <AppWrapper>
          <PageRoutes />
        </AppWrapper>
      </GameContextProvider>
    </BrowserRouter>
  )
}

export default App
