import React, { useMemo } from 'react'
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isTablet,
} from 'react-device-detect'
import BtnCopyUrl from './BtnCopyUrl'

const puzzleLogo = '/images/check/puzzle-logo.png'
const textNoSupportDesktop =
  '/images/check/text-no-support-desktop.png'
const dotsRight = '/images/check/dots-right.png'
const dotsLeft = '/images/check/dots-left.png'
const textUseChromeSafari = '/images/check/text-use-chrome-safari.png'

const AppWrapper: React.FC = ({ children }) => {
  const wrapperStyle = useMemo(() => {
    const width = window.innerWidth
    const height = window.innerHeight
    if (width >= (height * 3) / 4 || isTablet) {
      // 寬螢幕
      return {
        width: (height / 16) * 9,
        height,
        // overflow: 'hidden',
        // backgroundColor: '#0f05',
      }
    } else {
      // 長螢幕
      return {
        width,
        minHeight: (width / 9) * 16,
        // height: (width / 9) * 16,
        // overflow: 'hidden',
        // backgroundColor: '#0ff5',
      }
    }
  }, [])

  const isEligibleDevice =
    (isMobile || isTablet) && (isAndroid || isIOS)
  const isEligibleBrowser = /chrome|safari|samsung/i.test(browserName)
  const isLine = /line/i.test(browserName)

  if (!isEligibleDevice) {
    return (
      <div style={wrapperStyle}>
        <div className="center">
          <img
            src={dotsRight}
            style={{ width: 73, height: 32 }}
            alt="dots-right"
          />
          <div style={{ height: 60 }} />
          <img
            src={textNoSupportDesktop}
            style={{ width: 204, height: 201 }}
            alt="text-no-support-desktop"
          />
          <div style={{ height: 60 }} />
          <img
            src={dotsLeft}
            style={{ width: 134, height: 64.5 }}
            alt="dots-left"
          />
        </div>
      </div>
    )
  }
  if (isLine || !isEligibleBrowser) {
    return (
      <div style={wrapperStyle}>
        <div className="center">
          <img
            src={puzzleLogo}
            style={{ width: 108.5, height: 111.5 }}
            alt="puzzle-logo"
          />
          <div style={{ height: 20 }} />
          <img
            src={textUseChromeSafari}
            style={{ width: 302, height: 60, objectFit: 'contain' }}
            alt="text-use-chrome-safari"
          />
          <div style={{ height: 14 }} />
          <BtnCopyUrl />
        </div>
      </div>
    )
  }

  return <div style={wrapperStyle}>{children}</div>
}

export default AppWrapper
