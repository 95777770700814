// src/hooks/use-dimension.ts
import { useMemo } from 'react'
import { isTablet } from 'react-device-detect'
import { useWindowSize } from 'react-use'

export default function useDimension() {
  const { width, height } = useWindowSize()

  // dw = extra screen width
  // dh = extra screen height
  const [ws, wss, dw, dww, dh, dhh] = useMemo(() => {
    // const width = window.innerWidth
    // const height = window.innerHeight
    // if (width >= (height * 9) / 16) {
    if (width >= (height * 3) / 4 || isTablet) {
      // 寬螢幕
      const dw =
        width - (height / 16) * 9 > 0 ? width - (height / 16) * 9 : 0
      return [height / 640, height / 1280, dw, dw / 2, 0, 0]
    } else {
      // 長螢幕
      const dh =
        height - (width / 9) * 16 > 0 ? height - (width / 9) * 16 : 0
      return [width / 360, width / 720, 0, 0, dh, dh / 2]
    }
  }, [width, height])

  return { ws, wss, dw, dww, dh, dhh }
}
