// src/components/Fallback.tsx

import PageWrapper from './PageWrapper'
import './loader.css'

// ref: https://projects.lukehaas.me/css-loaders/

function Fallback() {
  return (
    <PageWrapper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="loader"></div>
    </PageWrapper>
  )
}

export default Fallback
